import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.PvGiftVouchersModule
      ),
  },
  {
    path: 'flights',
    loadChildren: () =>
      import('./flights/flights.module').then((m) => m.FlightsModule),
  },
  {
    path: 'sso_login',
    loadChildren: () =>
      import('@pv-frontend/sso-login').then((m) => m.SSOModule),
  },
  {
    path: 'points-xchange',
    loadChildren: () =>
      import('@pv-frontend/points-xchange').then(
        (m) => m.PvPointsXchangeModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.PvGiftVouchersModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
